import React from "react";
import { MuiTelInput, MuiTelInputProps } from "mui-tel-input";
import { useTheme } from "@patron/utils/theme";

export type CustomPhoneNumberComponentProps = MuiTelInputProps & {
  controlDisabled: boolean;
};

export const CustomPhoneNumberComponent = ({
  controlDisabled,
  ...otherProps
}: CustomPhoneNumberComponentProps) => {
  const theme = useTheme();
  return (
    <MuiTelInput
      variant="filled"
      size="small"
      // flagSize="small"
      defaultCountry="NL"
      continents={["EU"]}
      disabled={controlDisabled}
      sx={{
        "&.MuiTextField-root .Mui-disabled ": {
          //@ts-ignore
          backgroundColor: theme.appColors.surfaceContainer,
          //@ts-ignore
          color: theme.appPalette.neutral[30],
          pointerEvents: "unset",
          "&&:hover": {
            cursor: "default",
          },
        },
        "& .MuiInputBase-input.Mui-disabled": {
          //@ts-ignore
          color: theme.appPalette.neutral[30],
          //@ts-ignore
          WebkitTextFillColor: theme.appPalette.neutral[30],
        },
      }}
      id="outlined-adornment-PhoneNumber"
      aria-describedby="filled-PhoneNumber-helper-text"
      {...otherProps}
    />
  );
};

export default CustomPhoneNumberComponent;
