import { Stack, Autocomplete, TextField } from "@mui/material";
import { useTranslations } from "next-intl";
import React from "react";

interface CustomMultiTagComponentProps {
  inputValue?: string[];
  label: string;
  maxHeight: string | number;
  tagOptions: string[];
  disableCloseOnSelect: boolean;
  onTagValueChange: (value: string[]) => void;
}

export const CustomMultiTagComponent = ({
  inputValue,
  label,
  tagOptions,
  onTagValueChange,
  maxHeight,
  disableCloseOnSelect,
}: CustomMultiTagComponentProps) => {
  const [tagValue, SetTagValue] = React.useState([]);
  const t = useTranslations("labels");
  const [options, setOptions] = React.useState<any[]>(tagOptions || []);
  const handleOnTagValueChange = (input: string[]) => {
    SetTagValue(input);
    onTagValueChange(input);
  };
  React.useEffect(() => {
    if (tagOptions && tagOptions.length > 0) {
      setOptions(tagOptions);
    }
  }, [tagOptions]);

  React.useEffect(() => {
    if (inputValue && inputValue.length > 0 && options && options.length > 0) {
      const localArray = [];

      inputValue.forEach((element: string) => {
        const item = options.find((subItem: any) => subItem.id === element);
        if (item) {
          localArray.push(item);
        }
      });
      SetTagValue(localArray);
    }
  }, [options, inputValue]);

  return (
    <Stack id="CustomMultiTagComponent">
      <Autocomplete
        multiple
        id="tag-filled-component"
        options={options}
        sx={{ width: 450 }}
        limitTags={7}
        disableCloseOnSelect={disableCloseOnSelect}
        getOptionLabel={(option) => option.name}
        onChange={(event, val: any[]) => handleOnTagValueChange(val)}
        value={tagValue}
        renderInput={(params) => (
          <TextField
            {...params}
            variant="filled"
            label={t(label)}
            sx={{ maxHeight: maxHeight, overflow: "auto" }}
          />
        )}
      />
    </Stack>
  );
};

export default CustomMultiTagComponent;
