import React, { ChangeEvent } from "react";
import { SwitchProps, Box, Switch } from "@mui/material";

type CustomSwitchProps = SwitchProps & {
  controldisabled?: boolean;
  size?: string;
  value: boolean;
  onValueChanged: (
    event: ChangeEvent<HTMLInputElement>,
    checked: boolean
  ) => void;
};

export const CustomSwitch = ({
  size = "small",
  onValueChanged,
  ...props
}: CustomSwitchProps) => {
  const { value } = props;
  return (
    <Box id="CustomSwitch">
      <Switch size={size} checked={value} onChange={onValueChanged} />
    </Box>
  );
};

export default CustomSwitch;
