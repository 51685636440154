import React, { useState, useEffect, useCallback } from "react";
import { TextField, TextFieldProps } from "@mui/material";
import { ComponentProps } from "react";
import { IMaskMixin } from "react-imask";

const InternalMaskTextField = IMaskMixin((props) => (
  <TextField {...(props as any)} />
));

export type MaskProps = ComponentProps<typeof InternalMaskTextField> & {
  overrideMaskValue?: string;
};

export const MaskedTextField = ({
  overrideMaskValue,
  defaultValue,
  onChange,
  ...props
}: TextFieldProps & MaskProps) => {
  const [value, setValue] = useState(defaultValue?.toString() || "");
  const [timeoutId, setTimeoutId] = useState<NodeJS.Timeout | null>(null);

  useEffect(() => {
    if (overrideMaskValue) {
      setValue(overrideMaskValue);
    }
  }, [overrideMaskValue]);

  const handleAccept = useCallback(
    (v: string | unknown) => {
      setValue(v as string);

      if (timeoutId) {
        clearTimeout(timeoutId);
      }

      const newTimeoutId = setTimeout(() => {
        if (typeof v === "string") {
          const event = {
            target: { value: v },
          } as React.ChangeEvent<HTMLInputElement>;
          onChange?.(event);
        } else {
          onChange?.(v as any);
        }
      }, 300); // Adjust the delay as needed

      setTimeoutId(newTimeoutId);
    },
    [onChange]
  );

  useEffect(
    () => () => {
      if (timeoutId) {
        clearTimeout(timeoutId);
      }
    },
    [timeoutId]
  );

  return (
    <InternalMaskTextField
      {...props}
      mask={props.mask}
      unmask
      onAccept={handleAccept}
      placeholder={props.placeholder}
      value={value}
    />
  );
};
