/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable func-style */
import * as React from "react";
import Stack from "@mui/material/Stack";
import Snackbar from "@mui/material/Snackbar";
import Portal from "@mui/material/Portal";
import MuiAlert, { AlertProps, AlertColor } from "@mui/material/Alert";
/* eslint-disable-next-line */
export interface SnackBarProps {
  alert?: boolean;
  message: string;
  openState: boolean;
  severity: AlertColor | undefined;
  onClose: (inputClosed: boolean) => void;
}

// eslint-disable-next-line no-unused-vars
const Alert = React.forwardRef<HTMLDivElement, AlertProps>(function Alert(
  props,
  ref
) {
  return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});

export function SnackBar(props: SnackBarProps) {
  const { message, openState, onClose, severity } = props;
  const [open, setOpen] = React.useState(openState);
  const container = React.useRef(null);

  React.useEffect(() => {
    setOpen(openState);
  }, [openState]);

  const handleClose = (_event?: React.SyntheticEvent, reason?: string) => {
    if (reason === "clickaway") {
      return;
    }

    setOpen(false);
    onClose(false);
  };

  return (
    <Stack id="Snackbar" spacing={2} sx={{ width: "100%", marginLeft: 10 }}>
      <Portal container={container.current}>
        <Snackbar
          open={open}
          autoHideDuration={3000}
          //@ts-ignore
          onClose={handleClose}
          anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
        >
          <Alert
            onClose={handleClose}
            //@ts-ignore
            severity={severity}
            sx={{ width: "100%" }}
          >
            {message}
          </Alert>
        </Snackbar>
      </Portal>
    </Stack>
  );
}

export default SnackBar;
