import React from "react";
import Autocomplete, { AutocompleteProps } from "@mui/material/Autocomplete";
import { Box } from "@mui/material";
import { useTheme } from "@patron/utils/theme";

export const CustomAutoComplete = <T,>(
  params: AutocompleteProps<
    T,
    boolean | undefined,
    boolean | undefined,
    boolean | undefined
  >
) => {
  const theme = useTheme();
  return (
    <Autocomplete
      id="CustomAutoCompleteComponent"
      forcePopupIcon={params.disabled ? false : true}
      renderOption={(props, option, state, ownerState) => (
        <Box
          component="li"
          {...props}
          //@ts-ignore
          key={option.id}
          sx={{
            "&:hover": {
              backgroundColor: theme.appColors.primaryFixedDim,
            },
          }}
        >
          {ownerState.getOptionLabel?.(option)}
        </Box>
      )}
      // autoHighlight
      autoSelect
      disableClearable={params.disableClearable}
      {...params}
    />
  );
};

export default CustomAutoComplete;
