import * as React from "react";
import Stack from "@mui/material/Stack";
import CloseIcon from "@mui/icons-material/Close";
import { IconButton } from "@mui/material";
import { CustomTypographyComponent } from "@patron/ui/text";
import { useTheme } from "@patron/utils/theme";
import { parseCookies, setCookie } from "nookies";
import getConfig from "next/config";

export interface HelpbarProps {
  message: string;
  cookieName: string;
  onClose: (inputClosed: boolean) => void;
}

export const Helpbar = (props: HelpbarProps) => {
  const { message, onClose, cookieName } = props;
  const theme = useTheme();
  const [showMessage, SetShowMessage] = React.useState<boolean>(false);
  const cookies = parseCookies();
  const {
    publicRuntimeConfig: { TENANT_ENV },
  } = getConfig();
  React.useEffect(() => {
    if (cookies && cookies[cookieName] && TENANT_ENV !== "DEV") {
      SetShowMessage(false);
    } else {
      SetShowMessage(true);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [cookieName, cookies]);

  const handleClose = (_event?: React.SyntheticEvent, reason?: string) => {
    if (reason === "clickaway") {
      return;
    }
    setCookie(null, cookieName, "true");
    SetShowMessage(false);
    onClose(false);
  };

  return (
    // eslint-disable-next-line react/jsx-no-useless-fragment
    <>
      {showMessage && (
        <Stack
          mt={2}
          spacing={2}
          visibility={showMessage ? "visible" : "hidden"}
          padding={2}
          direction="row"
          sx={{
            width: "100%",
            backgroundColor: theme.appColors.secondaryContainer,
          }}
        >
          <Stack direction={"row"} width="100%">
            <Stack width="100%">
              <CustomTypographyComponent>{message}</CustomTypographyComponent>
            </Stack>
            <Stack direction="row-reverse" alignItems="center">
              <IconButton
                onClick={handleClose}
                sx={{
                  display: "flex",
                  width: "19px",
                  height: "19px",
                }}
              >
                <CloseIcon sx={{ color: "action.active", fontSize: 18.67 }} />
              </IconButton>
            </Stack>
          </Stack>
        </Stack>
      )}
    </>
  );
};

export default Helpbar;
