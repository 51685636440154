import React, { ReactNode } from "react";
import { FormControlLabel, Switch, SwitchProps } from "@mui/material";
type CustomToggleCompomentProps = SwitchProps & {
  controldisabled?: boolean;
  children?: ReactNode;
  color?: string;
};

export const CustomToggle = ({
  controldisabled = false,
  color,

  checked,
  ...props
}: CustomToggleCompomentProps) => (
  <Switch
    {...props}
    color={color}
    disabled={controldisabled}
    checked={checked}
  />
);

export default CustomToggle;
