import * as React from "react";
import { NumericFormat } from "react-number-format";
import CustomTextField, {
  CustomTextFieldProps,
} from "./CustomTextFieldComponent";
import InputAdornment from "@mui/material/InputAdornment";

type CustomCurrencyComponentProps = CustomTextFieldProps & {
  displayType?: any;
  onValue?: any;
  controlDisabled?: boolean;
};

const renderTextField = (props: CustomCurrencyComponentProps) => (
  <CustomTextField
    InputProps={{
      startAdornment: <InputAdornment position="start">€</InputAdornment>,
    }}
    {...props}
  />
);

export const CustomCurrencyComponent = ({
  onValue,
  displayType,
  controlDisabled,
  ...others
}: CustomCurrencyComponentProps) => (
  //@ts-ignore
  <NumericFormat
    customInput={renderTextField}
    disabled={controlDisabled}
    readOnly={controlDisabled}
    decimalSeparator=","
    thousandSeparator="."
    inputMode="numeric"
    decimalScale={2}
    displayType={displayType ? displayType : "input"}
    fixedDecimalScale
    onFocus={(event) => {
      event.target.select();
    }}
    onValueChange={(values) => {
      const { formattedValue, value, floatValue } = values;
      if (onValue) onValue(floatValue);
    }}
    {...others}
  />
);

export default CustomCurrencyComponent;
