import React, { ReactNode } from "react";
import { FormControlLabel, Switch, SwitchProps } from "@mui/material";
type CustomToggleCompomentProps = SwitchProps & {
  controldisabled?: boolean;
  children?: ReactNode;
  color?: string;
  label?: string;
};

export const CustomToggleCompoment = ({
  controldisabled = false,
  color,
  label,
  checked,
  ...props
}: CustomToggleCompomentProps) => (
  <FormControlLabel
    control={
      <Switch
        {...props}
        color={color}
        disabled={controldisabled}
        checked={checked}
      />
    }
    label={label}
  />
);

export default CustomToggleCompoment;
