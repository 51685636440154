import React from "react";
import { Stack, Box } from "@mui/material";
import Chip from "@mui/material/Chip";
import { CustomTypographyComponent } from "@patron/ui/text";

interface CustomTagComponentProps {
  data: any;
  handleDelete: any;
  controldisabled?: boolean;
  maxHeight?: any;
  label?: string;
}

export const CustomTagComponent = (props: CustomTagComponentProps) => {
  const { data, handleDelete, controldisabled, maxHeight, label } = props;
  return (
    <Box
      id="CustomTagComponent"
      sx={{
        height: "100%",
        display: "flex",
        mr: 0.6,
        justifyContent: "center",
        alignContent: "center",
        maxHeight: maxHeight,
      }}
    >
      <CustomTypographyComponent>{label}</CustomTypographyComponent>
      <Stack direction="row" padding={0} gap={1}>
        <Chip
          label={data}
          disabled={controldisabled}
          size="small"
          onDelete={!controldisabled ? () => handleDelete(data) : undefined}
          color="info"
        >
          {data}
        </Chip>
      </Stack>
    </Box>
  );
};

export default CustomTagComponent;
