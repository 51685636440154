import * as React from "react";
import Box from "@mui/material/Box";
import { Stack, TextFieldProps } from "@mui/material";
import CustomTextField from "./CustomTextFieldComponent";
import { CustomTypographyComponent } from "@patron/ui/text";
import CustomCheckBoxComponent from "./CustomCheckBoxComponent";
import { CustomNumberComponent } from "./CustomNumberComponent";

type CustomCheckBoxTextFieldComponentProps = TextFieldProps & {
  controldisabled?: boolean;
  width: any;
  labelText: string;
  min: number;
  max: number;
  step: number;
  onValueChanged: (valueParam: any, checkBoxInput: boolean) => void;
};

interface CustomCheckBoxTextFieldComponentState {
  checkBoxValue: boolean;
  checkboxNumber: any;
}

export const CustomCheckBoxTextFieldComponent = (
  props: CustomCheckBoxTextFieldComponentProps
) => {
  const {
    controldisabled,
    labelText,
    value,
    min,
    max,
    step,
    onValueChanged,
    width,
    ...others
  } = props;
  //@ts-ignore
  const [checkStateValue, setCheckStateValue] =
    React.useState<CustomCheckBoxTextFieldComponentState>({
      //@ts-ignore
      checkBoxValue: value.checked || false,
      //@ts-ignore
      checkboxNumber: value.amount || 0,
    });
  //@ts-ignore

  React.useEffect(() => {
    onValueChanged(
      checkStateValue.checkboxNumber,
      checkStateValue.checkBoxValue
    );
  }, [checkStateValue]);

  const handleSliderChange = (event: any) => {
    setCheckStateValue({
      ...checkStateValue,
      checkBoxValue: event.target.checked,
    });
  };

  const handleInputChange = (val: number) => {
    setCheckStateValue({
      ...checkStateValue,
      checkboxNumber: val,
    });
  };

  return (
    <Box padding={1} id="CustomSliderComponent" sx={{ width: width }}>
      <Stack
        width={"100%"}
        spacing={5}
        justifyContent="space-between"
        direction="row"
      >
        <CustomTypographyComponent id="input-slider" gutterBottom>
          {labelText}
        </CustomTypographyComponent>
        <Stack spacing={5} padding={0} direction={"row"}>
          <CustomCheckBoxComponent
            onChange={(event) => handleSliderChange(event)}
            controldisabled={false}
            checked={checkStateValue.checkBoxValue}
          />
          <Stack width={100} padding={0}>
            <CustomNumberComponent
              value={checkStateValue.checkboxNumber}
              placeholder="0"
              hiddenLabel
              onValue={handleInputChange}
              disabled={checkStateValue.checkBoxValue === false}
              variant="filled"
              suffix="%"
              isString
            />
          </Stack>
        </Stack>
      </Stack>
    </Box>
  );
};
export default CustomCheckBoxTextFieldComponent;
